
import { paymentTypes, subscriptionPlans, subscriptionPlansNew } from '@/constants';
import { SITE_KEY } from '@/services/google-services';
import PaymentServices from '@/services/payment-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SnotifyToast } from 'vue-snotify';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const prints = namespace('prints');

declare const grecaptcha: any;

@Component
export default class SubscriptionRenewal extends Vue {
  localResolve: any;
  localValue = false;
  authorization = '';
  paymentType = 'CC';
  planIndex = 0;
  message = '';
  widgetId = '';
  dropIn: any = null;
  voucher = '';
  authFetched = true;
  recaptchaVerified = false;
  recaptchaToken = '';

  @settings.Getter('getPromo')
  promo!: any;

  @settings.Getter('getAccountSettings')
  accountSettings!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Action
  pay!: (params?: any) => Promise<any>;

  @settings.Action
  applyVoucher!: (params?: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @prints.Action
  print!: (params?: any) => Promise<any>;

  get paidThroughDate(): string {
    return this.accountSettings.paidThroughDate;
  }

  $refs!: {
    recaptcha: HTMLElement,
    giftCardForm: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  get applyPromo() {
    return this.promo.paymentId === 0 && CommonUtils.hasText(this.promo.code);
  }

  get paymentTypeItems() {
    return paymentTypes;
  }

  get subscriptionPlans() {
    if (CommonUtils.isNewPricing20240701()) {
      return subscriptionPlansNew;
    }
    return subscriptionPlans;
  }

  get hasAuthorization() {
    return CommonUtils.hasText(this.authorization);
  }

  get promoMsg() {
    return this.$t('promoSummaryMsg', { code: this.promo.code, saving: this.promo.amount, total: this.total })
  }

  get total() {
    return +this.currentPlan.price - (this.promo.amount || 0);
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.message) && this.authFetched;
  }

  get currentPlan() {
    return this.subscriptionPlans[this.planIndex];
  }

  public getSubtitle(item: any) {
    let mPaidThrough = moment(this.paidThroughDate, 'MM/DD/YYYY').tz(moment.tz.guess());
    if (mPaidThrough.isBefore()) {
      mPaidThrough = moment();
    }
    const newPaidThrough = DateTimeUtils.formatToShow(mPaidThrough.add(item.years, 'years').format('MM/DD/YYYY'));
    return this.$t('extendSubscriptionToDateLabel', { date: newPaidThrough });
  }

  @Watch('localValue')
  onLocalValueChange() {
    this.message = '';
    this.authFetched = false;
    if (this.localValue) {
      CommonUtils.showLoading();
      PaymentServices.getPaymentToken().then(resp => {
        this.authorization = resp.data.token;
        this.authFetched = true;
        if (CommonUtils.hasText(this.authorization)) {
          this.message = '';
        }
      }).finally(CommonUtils.hideLoading);
    }
  }

  @Watch('paymentType')
  onPaymentTypeChanged() {
    if (this.paymentType === 'GC') {
      this.$nextTick(() => {
        this.renderRecaptcha();
      })
    }
  }

  @Watch('planIndex')
  onPlanIndexChange() {
    if (this.dropIn) {
      this.dropIn.updateConfiguration('paypal', 'amount', this.total);
    }
  }

  renew() {
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  onLoad(data: any) {
    this.dropIn = data;
    this.dropIn.updateConfiguration('paypal', 'amount', this.total);
    CommonUtils.hideLoading();
    this.renderRecaptcha();
  }

  onLoadFail(data: any) {
    this.message = data.message;
    CommonUtils.hideLoading();
  }

  async onSuccess(data: any) {
    const request = {
      nonce: data.nonce,
      recaptcha: this.recaptchaToken,
      years: this.currentPlan.years,
      price: this.total,
      promoCode: this.promo.code || ''
    }
    return this.pay(request).then(resp => {
      const data = resp.data;
      if (this.showSnackbarNotifications) {
        this.$snotify.confirm(this.$t('paymentReceived') as string, {
          timeout: 20000,
          showProgressBar: false,
          buttons: [
            {
              text: this.$t('printReceiptLabel') as string,
              action: (toast: SnotifyToast) => {
                this.$snotify.remove(toast.id, true);
                this.printInvoice(data.invoiceId);
              },
              bold: true
            },
            {
              text: this.$t('closeLabel') as string,
              action: (toast: SnotifyToast) => {
                this.$snotify.remove(toast.id);
              }
            }
          ]
        });
      }
      return Promise.resolve();
    }).catch(err => {
      if (err.data && CommonUtils.isNotEmpty(err.data.messages)) {
        this.message = err.data.messages[0];
      } else if (err.data && err.data.data && CommonUtils.isNotEmpty(err.data.data.messages)) {
        this.message = err.data.data.messages[0];
      } else {
        this.message = this.$t('contactSupport') as string;
      }
      return Promise.resolve({ error: true });
    }).then((result: any) => {
      if (!result?.error) {
        this.localValue = false;
        this.localResolve();
      }
    }).finally(() => {
      this.recaptchaVerified = false;
      this.recaptchaToken = '';
      grecaptcha.reset();
      this.dropIn.clearSelectedPaymentMethod();
      CommonUtils.hideLoading();
    });
  }

  printInvoice(id: string) {
    CommonUtils.showLoading();
    this.print({
      printId: id,
      printType: this.userMode === 'T' ? 'T' : 'I',
      exportType: 'pdf'
    }).finally(CommonUtils.hideLoading)
  }

  onError(data: any) {
    this.message = data.message;
    CommonUtils.hideLoading();
  }

  doApply(data: any) {
    this.message = '';
    if (data && data.submit) {
      CommonUtils.showLoading();
      data.submit();
    } else {
      if (this.$refs.giftCardForm.validate()) {
        CommonUtils.showLoading();
        this.applyVoucher({ voucher: this.voucher }).catch(err => {
          if (err.data && err.data.data && CommonUtils.isNotEmpty(err.data.data.message)) {
            this.message = err.data.data.message;
          } else {
            this.message = this.$t('contactSupport') as string;
          }
          return Promise.reject(err);
        }).then(() => {
          this.$refs.giftCardForm.resetValidation();
          this.localValue = false;
          this.localResolve();
        }).finally(CommonUtils.hideLoading)
      }
    }
  }

  onRecaptchaSuccess(token: string) {
    this.recaptchaVerified = true;
    this.recaptchaToken = token;
  }

  renderRecaptcha() {
    this.recaptchaVerified = false;
    this.recaptchaToken = '';
    grecaptcha.render(document.getElementById('g-recaptcha'), {
      sitekey: SITE_KEY,
      callback: this.onRecaptchaSuccess
    });
  }
}
