
import { SITE_KEY } from '@/services/google-services';
import PaymentServices from '@/services/payment-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SnotifyToast } from 'vue-snotify';
import { namespace } from 'vuex-class';

const settings = namespace('settings');
const prints = namespace('prints');
declare const grecaptcha: any;

@Component
export default class SubscriptionRenewal extends Vue {
  localResolve: any;
  localValue = false;
  authFetched = true;
  authorization = '';
  message = '';
  dropIn: any = null;
  invoice: any = {};
  recaptchaVerified = false;
  recaptchaToken = '';

  @settings.Getter('getAccountSettings')
  accountSettings!: any;

  @settings.Action('pay')
  doPay!: (params?: any) => Promise<any>;

  @prints.Action
  print!: (params?: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get invoiceId() {
    return this.invoice.paymentId || 0;
  }

  get accounts() {
    return this.invoice.numTeachers || 0;
  }

  get subscriptionEnds() {
    return DateTimeUtils.formatToShow(this.invoice.paidThroughDate || '01/01/0001');
  }

  get total() {
    return `US $${(+this.invoice.paymentAmount || 0).toFixed(2)}`;
  }

  get price() {
    return +this.invoice.paymentAmount || 0;
  }

  get paidThroughDate(): string {
    return this.accountSettings.paidThroughDate;
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.message) && this.authFetched;
  }

  submitPayment(data: any) {
    this.message = '';
    CommonUtils.showLoading();
    data.submit();
  }

  onLoad(data: any) {
    this.dropIn = data;
    this.dropIn.updateConfiguration('paypal', 'amount', this.price);
    this.$nextTick(() => {
      this.renderRecaptcha();
    });
    CommonUtils.hideLoading();
  }

  onLoadFail(data: any) {
    this.message = data.message;
    CommonUtils.hideLoading();
  }

  async onSuccess(data: any) {
    return this.doPay({
      nonce: data.nonce,
      invoiceId: this.invoiceId,
      price: this.price,
      recaptcha: this.recaptchaToken
    }).then(resp => {
      const data = resp.data;
      if (this.showSnackbarNotifications) {
        this.$snotify.confirm(this.$t('paymentReceived') as string, {
          timeout: 20000,
          showProgressBar: false,
          buttons: [
            {
              text: this.$t('printReceiptLabel') as string,
              action: (toast: SnotifyToast) => {
                this.$snotify.remove(toast.id, true);
                this.printInvoice(data.invoiceId);
              },
              bold: true
            },
            {
              text: this.$t('closeLabel') as string,
              action: (toast: SnotifyToast) => {
                this.$snotify.remove(toast.id);
              }
            }
          ]
        });
      }
      return Promise.resolve();
    }).catch(err => {
      if (err.data && CommonUtils.isNotEmpty(err.data.messages)) {
        this.message = err.data.messages[0];
      } else if (err.data && err.data.data && CommonUtils.isNotEmpty(err.data.data.messages)) {
        this.message = err.data.data.messages[0];
      } else {
        this.message = this.$t('contactSupport') as string;
      }
      return Promise.resolve({ error: true });
    }).then((result: any) => {
      if (!result?.error) {
        this.localValue = false;
        this.localResolve();
      }
    }).finally(() => {
      this.recaptchaVerified = false;
      this.recaptchaToken = '';
      grecaptcha.reset();
      this.dropIn.clearSelectedPaymentMethod();
      CommonUtils.hideLoading();
    });
  }

  onError(data: any) {
    this.message = data.message;
    CommonUtils.hideLoading();
  }

  public pay(invoice: any) {
    this.invoice = invoice;
    this.localValue = true;
    return new Promise((resolve) => {
      this.localResolve = resolve;
    });
  }

  printInvoice(id: string) {
    CommonUtils.showLoading();
    this.print({
      printId: id,
      printType: this.userMode === 'T' ? 'T' : 'I',
      exportType: 'pdf'
    }).finally(CommonUtils.hideLoading)
  }

  @Watch('localValue')
  onLocalValueChange() {
    this.message = '';
    this.authFetched = false;
    if (this.localValue) {
      CommonUtils.showLoading();
      PaymentServices.getPaymentToken().then(resp => {
        this.authorization = resp.data.token;
        this.authFetched = true;
        if (CommonUtils.hasText(this.authorization)) {
          this.message = '';
        }
      }).finally(CommonUtils.hideLoading);
    }
  }

  onRecaptchaSuccess(token: string) {
    this.recaptchaVerified = true;
    this.recaptchaToken = token;
  }

  renderRecaptcha() {
    this.recaptchaVerified = false;
    this.recaptchaToken = '';
    grecaptcha.render(this.$refs.recaptcha, {
      sitekey: SITE_KEY,
      callback: this.onRecaptchaSuccess
    });
  }
}
