var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 500,
        withActions: false,
        expandable: false,
        withApply: false,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_c("div", [_vm._v(_vm._s(_vm.$t("payInvoiceLabel")))])]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-expand-transition",
            [
              _vm.hasErrorMsg
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                dense: "",
                                border: "left",
                                type: "error",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-table pl-5 pb-2",
                    staticStyle: { "font-size": "18px" },
                  },
                  [
                    _c("div", { staticClass: "d-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-table-cell text-right pr-3 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("invoiceLabel")))]
                      ),
                      _c("div", { staticClass: "d-table-cell" }, [
                        _vm._v(_vm._s(_vm.invoiceId)),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-table-cell text-right pr-3 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("accountsLabel")))]
                      ),
                      _c("div", { staticClass: "d-table-cell" }, [
                        _vm._v(_vm._s(_vm.accounts)),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-table-cell text-right pr-3 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("subscriptionEndsLabel")))]
                      ),
                      _c("div", { staticClass: "d-table-cell" }, [
                        _vm._v(_vm._s(_vm.subscriptionEnds)),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-table-cell text-right pr-3 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.$t("paymentLabel")))]
                      ),
                      _c("div", { staticClass: "d-table-cell" }, [
                        _vm._v(_vm._s(_vm.total)),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  key: _vm.authorization,
                  staticClass: "pt-0 mb-2",
                  attrs: { cols: "12" },
                },
                [
                  _c("v-braintree", {
                    ref: "braintree",
                    attrs: {
                      paypal: {
                        flow: "checkout",
                        amount: "50.00",
                        currency: "USD",
                      },
                      authorization: _vm.authorization,
                    },
                    on: {
                      load: _vm.onLoad,
                      loadFail: _vm.onLoadFail,
                      success: _vm.onSuccess,
                      error: _vm.onError,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button",
                        fn: function (data) {
                          return [
                            _c("div", {
                              ref: "recaptcha",
                              staticClass: "g-recaptcha",
                              attrs: {
                                "data-theme": _vm.$vuetify.theme.dark
                                  ? "dark"
                                  : undefined,
                              },
                            }),
                            _c(
                              "v-card-actions",
                              { staticClass: "pa-0 pt-2" },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      color: "error",
                                      disabled: !_vm.recaptchaVerified,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.submitPayment(data)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("submitPaymentLabel")))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }